import {Component} from '@angular/core';
import {Modal} from "../../../_modal/modal-options";

@Component({
  selector: 'app-expert-page-success-consultation-modal',
  templateUrl: './expert-page-consultation-success-modal.component.html',
  styleUrls: ['./expert-page-consultation-success-modal.component.scss']
})
export class ExpertPageConsultationSuccessModalComponent extends Modal {
  
  shedule: any|null = null;
  sheduleId: any|null = null;
  expert: any|null = null;

  onInjectInputs(inputs: any): void {
    this.shedule = inputs['shedule'];
    this.sheduleId = inputs['sheduleId'];
    this.expert = inputs['expert']
  }

  agreeShedule() {
    this.send({agree: true});
  }
}
