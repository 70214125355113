<section class="expert-page" id="expert-page">
  <app-preloader
    *ngIf="
      loadingService.States[
        loadingService.getIdFromArrayStateByType(
          loadingService.StatesNames.GET_EXPERT
        )
      ].value;
      else info
    "
  >
  </app-preloader>
  <ng-template #info>
    <div class="expert-page__container container">
      <h2 class="expert-page__title">
        {{ Expert.name }}
      </h2>
      <div class="expert-page__row">
        <img
          src="../../../../assets/img/squiggle_grey.svg"
          alt="Expert Image"
          class="expert-page__svg"
        />
        <div class="expert-page__col">
          <div class="expert-page__img">
            <img
              *ngIf="Expert.avatarUrl"
              dz-img
              [src]="Expert.avatarUrl"
              alt="Expert Image"
              class="object-fit"
            />
          </div>
        </div>
        <div class="expert-page__col expert-page__content">
          <div class="expert-page__block">
            <h5 class="expert-page__subtitle expert-page__text">
              {{ Expert.specialization }}
            </h5>
            <div class="expert-page__text expert-page__text_orange">
              {{ Expert.level }} уровень
            </div>
          </div>
          <div class="expert-page__block">
            <h5 class="expert-page__subtitle">Уровень пакета</h5>
            <div class="expert-page__text">
              {{ Expert.tariff }}
            </div>
          </div>
          <div class="expert-page__block">
            <h5 class="expert-page__subtitle">Опыт работы (лет)</h5>
            <div class="expert-page__text">
              {{ Expert.experience }}
            </div>
          </div>
          <div class="expert-page__block">
            <h5 class="expert-page__subtitle">Сферы жизни:</h5>
            <ul class="expert-page__list">
              <li
                class="expert-page__item"
                *ngFor="let sphere of Expert.spheres"
              >
                {{ sphere['name'] }}
              </li>
            </ul>
          </div>
          <button
            *ngIf="!btnVisible"
            (click)="scrollToElement(expertPageSchedule)"
            class="expert-page__scroll btn-custom btn-approve"
          >
            Выбрать время консультации
          </button>
        </div>
      </div>
      <div class="expert-page-tab">
        <div class="expert-page-tab__tabs">
          <div
            class="expert-page-tab__tab"
            [class.expert-page-tab__tab_active]="activeTab === 1"
            (click)="activeTab = 1"
          >
            О мастере
          </div>
          <div
            class="expert-page-tab__tab"
            [class.expert-page-tab__tab_active]="activeTab === 2"
            (click)="activeTab = 2"
          >
            Услуги
          </div>
          <div
            class="expert-page-tab__tab"
            [class.expert-page-tab__tab_active]="activeTab === 3"
            (click)="activeTab = 3"
          >
            Образование
          </div>
        </div>
        <div class="expert-page-tab__content">
          <div *ngIf="activeTab == 1" class="expert-page-tab__list">
            <h5 class="expert-page-tab__subtitle expert-page__subtitle">
              Личная информация
            </h5>
            <div class="expert-page-tab__text">
              <p class="preline">
                {{ Expert.additionalInfo }}
              </p>
            </div>
          </div>
          <div *ngIf="activeTab == 2" class="expert-page-tab__list">
            <h5 class="expert-page-tab__subtitle expert-page__subtitle">
              Услуги
            </h5>
            <div class="expert-page-tab__text">
              <p class="preline">
                {{ Expert.offers }}
              </p>
            </div>
          </div>
          <div *ngIf="activeTab == 3" class="expert-page-tab__list">
            <h5 class="expert-page-tab__subtitle expert-page__subtitle">
              Образование
            </h5>
            <ul class="expert-page-tab__list expert-page__list">
              <li
                class="expert-page-tab__item expert-page__item"
                *ngFor="let education of Expert.educations"
              >
                <div class="expert-page-tab__text">
                  <div class="expert-page-tab__head">
                    <b>{{ education.name }}</b
                    >&nbsp;
                    <span
                      >({{ education.startDate | date : "dd.MM.YYYY" }} -
                    </span>
                    <span>{{ education.endDate | date : "dd.MM.YYYY" }})</span>
                  </div>
                  <div class="expert-page-tab__body">
                    <p>
                      {{ education.place }}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div #expertPageSchedule class="expert-page-schedule">
        <h2 class="expert-page-schedule__title">Расписание эксперта</h2>
        <app-preloader
          *ngIf="!isLoadedShedule; else shedule_container"
        ></app-preloader>
        <ng-template #shedule_container>
          <div
            *ngIf="shedule.length; else noshedule_container"
            class="expert-page-schedule__content"
          >
            <div *ngFor="let s of shedule" class="expert-page-schedule__block">
              <h4 class="expert-page-schedule__subtitle schedule__subtitle">
                {{ s.date }}
              </h4>
              <ul class="expert-page-schedule__list schedule__list">
                <li
                  *ngFor="let dt of s.shedules"
                  class="expert-page-schedule__item schedule__item"
                  (click)="setSheduleItem(dt)"
                >
                  <div class="expert-page-schedule__elem schedule">
                    {{ dt.time }}
                    <!-- {{ dt.scheduleId == selectedSheduleId ? "Active" : "" }} -->
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <ng-template #noshedule_container>
            <p>Нет доступного расписания :(</p>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </ng-template>
  <div *ngIf="btnVisible">
    <button class="expert-page__btn btn-custom btn-approve" type="submit">
      Купить
    </button>
    <a [routerLink]="'c/profile'" class="expert-page__link">
      Перейти в личный кабинет
    </a>
  </div>
</section>
