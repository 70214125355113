<header>
  <div class="header__container container">
    <nav>
      <div class="left-wrapper">
        <!-- <div class="logo-wrapper"> -->
        <img src="../../../assets/img/logo.png" alt="logo" class="logo" />
        <!-- </div> -->
        <ul class="menu">
          <li *ngFor="let link of links">
            <a
              routerLink="{{ link.link }}"
              [routerLinkActive]="['is-active']"
              [routerLinkActiveOptions]="{ exact: false }"
            >
              {{ link.name }}
            </a>
          </li>
        </ul>
      </div>
      <ul class="menu-auth__list menu auth">
        <a
          *ngIf="authService.IsLogined && authService.User.mainForm == null"
          (click)="clickLogout()"
          >Выйти</a
        >

        <!-- (mouseover)="isShowSubmenuHover = true"
        (mouseout)="isShowSubmenuHover = false" -->
      

        <div
          *ngIf="authService.IsLogined && authService.User.mainForm"
          class="user-data"
        >
          <div class="user-info">
            <p class="username" (click)="isShowSubmenu = !isShowSubmenu">
              {{ authService.User.mainForm.name }}
            </p>
            <div
              class="user-submenu"
              [ngClass]="{ show: isShowSubmenu || isShowSubmenuHover }"
            >
              <ul class="sub-menu">
                <li
                  *ngFor="let sublink of subLinks"
                  class="sub-menu-item"
                  (click)="isShowSubmenu = false"
                  routerLink="{{ sublink.link }}"
                >
                  {{ sublink.name }}
                </li>
                <li
                  *ngIf="authService.IsLogined"
                  class="sub-menu-item exit"
                  (click)="clickLogout()"
                >
                  Выход
                </li>
                <li></li>
              </ul>
            </div>
          </div>
          <p *ngIf="unreadCounter$ | async" class="notification">{{ unreadCounter$ | async }}</p>
        </div>

        <li *ngIf="!authService.IsLogined">
          <a [routerLink]="['auth']" [routerLinkActive]="['is-active']"
            >Войти</a
          >
        </li>

        <li *ngIf="authService.User.role !== 'EXPERT'">
          <p class="choose-expert" routerLink="/c/recommend">Выбрать эксперта</p>
        </li>
      </ul>
    </nav>
  </div>
</header>
