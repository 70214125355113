<ng-template #headerRef>
  <h5 class="expert-page-consultation-modal__title modal-title">Вы успешно записаны!</h5>
</ng-template>
<app-modal [header]="headerRef" (destroy)="dismiss()">
  <div modal-content class="expert-page-consultation-modal__content">
    <ul class="expert-page-consultation-modal__list">
      <li class="expert-page-consultation-modal__item">
        Эксперт
        <b>
         {{expert['name'] ?? 'Ошибка'}}
        </b>
      </li>
      <li class="expert-page-consultation-modal__item">
        Дата
        <b>
          {{shedule['ruDate'] ?? 'Ошибка'}}
        </b>
      </li>
      <li class="expert-page-consultation-modal__item">
        Время
        <b>
          {{shedule['time'] ?? 'Ошибка '}}
        </b>
      </li>
    </ul>
    <!-- <div class="expert-page-consultation-modal__text">
      Вы точно хотите записаться к эксперту?
    </div> -->
    <button class="expert-page-consultation-modal__btn modal-submit btn-custom btn-approve btn-orange" (click)="agreeShedule()">Отлично!</button>
    <!-- <button class="btn-custom btn-none" (click)="dismiss()">Отмена</button> -->
  </div>
</app-modal>
