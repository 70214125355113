import { CommonModule, registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './features/main/about/about.component';
import { ExpertsComponent } from './features/main/experts/experts.component';
import { LandingComponent } from './features/main/landing/landing.component';
import { MainComponent } from './features/main/main.component';
import { UIModule } from './ui/ui.module';

registerLocaleData(localeRu, 'ru');

import { ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { environment } from 'src/environments/environment';
import { register } from 'swiper/element/bundle';
import { provideImagePathPrefix } from './directives/image.directive';
import { ContactsComponent } from './features/main/contacts/contacts.component';
import { FaqComponent } from './features/main/faq/faq.component';
import { AdvantagesComponent } from './features/main/landing/advantages/advantages.component';
import { ReviewsComponent } from './features/main/landing/reviews/reviews.component';
import { PublicExpertComponent } from './features/main/public-expert/public-expert.component';
import { VacancyComponent } from './features/main/vacancy/vacancy.component';
import { ErrorInterceptor } from './services/helpers/error.interecepter';
import { MainInterceptor } from './services/helpers/main.interceptor';

import * as Sentry from '@sentry/angular';

register();

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LandingComponent,
    ExpertsComponent,
    AboutComponent,
    FaqComponent,
    AdvantagesComponent,
    ReviewsComponent,
    PublicExpertComponent,
    VacancyComponent,
    ContactsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    CommonModule,
    HttpClientModule,
    UIModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    NgxPaginationModule,
    ReactiveFormsModule,
  ],
  providers: [
    HttpClient,
    { provide: HTTP_INTERCEPTORS, useClass: MainInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    provideImagePathPrefix(environment.apiUrl),
    {
      provide: LOCALE_ID,
      useValue: 'ru',
    },

    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [
    // HowWorksSliderComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
