<ng-template #headerRef>
  <h5 class="expert-page-consultation-modal__title modal-title">{{expert['tariff'] ?? '-'}}</h5>
</ng-template>
<app-modal [header]="headerRef" (destroy)="dismiss()">
  <div modal-content class="expert-page-consultation-modal__content">
    <ul class="expert-page-consultation-modal__list">
      <li class="expert-page-consultation-modal__item">
        Эксперт
        <b>
         {{expert['name'] ?? 'Ошибка'}}
        </b>
      </li>
    </ul>
    <div class="expert-page-consultation-modal__text">
      У вас недостаточно доступных консультаций
    </div>
    <button class="expert-page-consultation-modal__btn modal-submit btn-custom btn-approve btn-orange" (click)="agreeShedule()">Пополнить</button>
    <button class="btn-custom btn-none" (click)="dismiss()">Отмена</button>
  </div>
</app-modal>
