import { emitDistinctChangesOnlyDefaultValue } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { ModalService } from '../../_modal';
import { ExpertFullModel } from '../../models/experts.model';
import { AuthService } from '../../services/auth.service';
import { ExpertsService } from '../../services/experts.service';
import { LoadingService } from '../../services/loading.service';
import { ExpertPageConsultationModalComponent } from './expert-page-consultation-modal/expert-page-consultation-modal.component';
import {ExpertChooseModalComponent} from "../expert-choose-modal/expert-choose-modal.component";
import { ExpertPageConsultationGoPayModalComponent } from './expert-page-consultation-go-pay-modal/expert-page-consultation-go-pay-modal.component';
import { Router } from '@angular/router';
import { ExpertPageConsultationSuccessModalComponent } from './expert-page-consultation-success-modal/expert-page-consultation-success-modal.component';

@Component({
  selector: 'app-expert-page',
  templateUrl: './expert-page.component.html',
  styleUrls: ['./expert-page.component.scss'],
})
export class ExpertPageComponent implements OnInit {
  activeTab: number = 1;

  Expert: ExpertFullModel = {
    id: 0,
    name: '',
    level: 0,
    specialization: '',
    avgRating: 0,
    additionalInfo: '',
    avatarUrl: '',
    experience: '',
    spheres: [],
    tariffId: 0,
    tariff: '',
    offers: '',
    educations: [],
  };

  shedule: { date: string; shedules: any[] }[] = [];
  selectedSheduleId: number | null = null;
  isLoadedShedule = false;
  IsLogined = this.authService.IsLogined;
  @Input() Id = 0;
  @Input() btnVisible: boolean = false;

  constructor(
    private expertsService: ExpertsService,
    public loadingService: LoadingService,
    private ModalService: ModalService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService.SetChooseExpert(null);
    this.GetExpertInfo()
      .pipe(
        switchMap(() => {
          return this.getExpertShedule();
        })
      )
      .subscribe();
  }

  GetExpertInfo() {
    return this.expertsService.GetExpertInfo(this.Id).pipe(
      tap((res) => {
        this.Expert = res;
      })
    );
  }

  getExpertShedule() {
    this.isLoadedShedule = false;
    this.selectedSheduleId = null;
    this.shedule = [];
    return this.expertsService.GetExpertSheduleByClient(this.Id).pipe(
      catchError((err) => {
        console.log('error = ', err);

        return EMPTY;
      }),
      tap((res) => {
        this.convertSheduleToShow(res);
      }),
      finalize(() => {
        console.log('finalize getExpertShedule');
        this.isLoadedShedule = true;
      })
    );
  }

  setSheduleItem(s: any) {
    //console.log('shedule item = ', s);
    if(this.IsLogined){
      this.selectedSheduleId = s.scheduleId;
      this.openExpertPageConsultationModal(s);
    } else{
      this.SaveExpertModal();

    }

  }

  SaveExpertModal(){
     this.ModalService.open(ExpertChooseModalComponent, {id:this.Id}).onResult().subscribe()
  }

  protected convertSheduleToShow(shed: any[]) {
    let datasInfo: any = {};
    for (let item of shed) {
      const dateTime = new Date(item['dateTime']);
      const m = dateTime.getMonth();
      const d = dateTime.getDate();
      const h = dateTime.getHours();
      const key = `${m}_${d}`;
      if (!(key in datasInfo)) {
        datasInfo[key] = {
          date: this.getRuDate(dateTime),
          shedules: [],
        };
      }
      datasInfo[key]['shedules'].push({
        ...item,
        hour: h,
        ruDate: datasInfo[key]['date'],
        time: `${this.timeFromHour(h)} - ${this.timeFromHour(h + 1)}`,
      });
    }
    for (let k of Object.keys(datasInfo)) {
      this.shedule.push(datasInfo[k]);
    }
    console.log(this.shedule);
  }

  getRuDate(date: Date) {
    const ruDate = date.toLocaleString('ru', {
      month: '2-digit',
      day: '2-digit',
      weekday: 'short',
      year: 'numeric',
    });
    let split = ruDate.slice(0, -3).split(', ');
    return (
      split[1] + ' - ' + (split[0].at(0)?.toUpperCase() + split[0].slice(1))
    );
  }

  timeFromHour(h: number) {
    const hChanged = h > 23 || h < 0 ? 0 : h;
    return `${hChanged.toString().length < 2 ? '0' : ''}${hChanged}:00`;
  }

  protected readonly emitDistinctChangesOnlyDefaultValue =
    emitDistinctChangesOnlyDefaultValue;

  scrollToElement($element: any) {
    $element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  openExpertPageConsultationModal(s: any) {
    const modal = this.ModalService.open(ExpertPageConsultationModalComponent, {
      sheduleId: this.selectedSheduleId,
      shedule: s,
      expert: this.Expert,
    });
    modal
      .onResult()
      .pipe(
        catchError(() => {
          return EMPTY;
        }),
        switchMap((_: any) => {
          if (_['agree']) {
            console.log('agree');
            return this.expertsService
              .SetExpertSheduleByClient(this.selectedSheduleId!)
              .pipe(
                catchError((err)=>{
                  // if((err.error.errors as Array<string>).filter(x=>x.indexOf('Must be not before 12 Hours from now') > -1).length) {
                  //   modal.injectInputs({error: 'Ближайшая запись должна быть не ранее, чем за 12 часов!'});
                  // }
                  if(err.error.status == 402) {
                    modal.dismiss(true);
                    this.openGoToPayModal();
                  }
                  return EMPTY;
                }),
                tap((__) => {
                  this.openSuccessModal(s);
                }),
                finalize(() => {
                  console.log('finalize');
                  this.getExpertShedule().subscribe();
                 
                })
              );
          } else {
            console.log('not agree');
            return EMPTY;
          }
        })
      )
      .subscribe(() => {
        modal.dismiss(true);
      });
  }

  openGoToPayModal() {
    const modal = this.ModalService.open(ExpertPageConsultationGoPayModalComponent, {
      expert: this.Expert,
    });
    modal
      .onResult()
      .pipe(
        catchError(() => {
          return EMPTY;
        }),
        switchMap((_: any) => {
          if (_['agree']) {
            modal.dismiss(true);
            this.router.navigate(['/c/profile/pay-packages']);
            return EMPTY;
          } else {
            console.log('not agree');
            return EMPTY;
          }
        })
      )
      .subscribe(() => {
        modal.dismiss(true);
      });
  }

  openSuccessModal(s: any) {
    const modal = this.ModalService.open(ExpertPageConsultationSuccessModalComponent, {
      sheduleId: this.selectedSheduleId,
      shedule: s,
      expert: this.Expert,
    });
    modal
      .onResult()
      .pipe(
        catchError(() => {
          return EMPTY;
        }),
        // switchMap((_: any) => {
        //   if (_['agree']) {
        //     console.log('agree');
        //     return EMPTY;
        //     // return this.expertsService
        //     //   .SetExpertSheduleByClient(this.selectedSheduleId!)
        //     //   .pipe(
        //     //     catchError((err)=>{
        //     //       // if((err.error.errors as Array<string>).filter(x=>x.indexOf('Must be not before 12 Hours from now') > -1).length) {
        //     //       //   modal.injectInputs({error: 'Ближайшая запись должна быть не ранее, чем за 12 часов!'});
        //     //       // }
        //     //       if(err.error.status == 402) {
        //     //         modal.dismiss(true);
        //     //         this.openGoToPayModal();
        //     //       }
        //     //       return EMPTY;
        //     //     }),
        //     //     tap((__) => {}),
        //     //     finalize(() => {
        //     //       console.log('finalize');
        //     //       this.getExpertShedule().subscribe();
        //     //       this.openSuccessModal(s);
        //     //     })
        //     //   );
        //   } else {
        //     console.log('not agree');
        //     return EMPTY;
        //   }
        // })
      )
      .subscribe(() => {
        modal.dismiss(true);
      });
  }
}
