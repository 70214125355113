import {Component} from '@angular/core';
import {Modal} from "../../../_modal/modal-options";

@Component({
  selector: 'app-expert-page-go-pay-consultation-modal',
  templateUrl: './expert-page-consultation-go-pay-modal.component.html',
  styleUrls: ['./expert-page-consultation-go-pay-modal.component.scss']
})
export class ExpertPageConsultationGoPayModalComponent extends Modal {

  expert: any|null = null;

  onInjectInputs(inputs: any): void {
    this.expert = inputs['expert']
  }

  agreeShedule() {
    this.send({agree: true});
  }
}
