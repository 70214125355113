import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, switchMap, tap } from 'rxjs';
import { ClientInfoService } from 'src/app/services/client-info.service';
import { EventsService } from 'src/app/services/events.service';
import { exists } from 'src/app/services/helpers/exists.operator';
import { Modal } from '../../_modal/modal-options';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-client-info-modal',
  templateUrl: './client-info-modal.component.html',
  styleUrls: ['./client-info-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientInfoModalComponent extends Modal {
  private readonly infoService = inject(ClientInfoService);
  private readonly eventsService = inject(EventsService);

  private readonly clientIdSubject$ = new Subject<number | null>();

  private readonly clientId$ = this.clientIdSubject$
    .asObservable()
    .pipe(exists());

  private readonly clientId = toSignal(this.clientId$);

  protected readonly activeTab = signal(0);

  protected readonly specialization = toSignal(
    this.infoService.getSpecialization()
  );

  protected readonly client = toSignal(
    this.clientId$.pipe(
      switchMap((id) => this.infoService.getClient(id)),
      tap((c) => this.commentControl.setValue(c.expertComment ?? ''))
    )
  );

  private readonly currentList = toSignal(
    this.clientId$.pipe(
      switchMap((id) => this.infoService.getClientSessionsList(id, false))
    )
  );
  private readonly closedList = toSignal(
    this.clientId$.pipe(
      switchMap((id) => this.infoService.getClientSessionsList(id, true))
    )
  );

  protected readonly list = computed(() =>
    this.activeTab() === 0 ? this.currentList() : this.closedList()
  );

  private readonly commentControl = new FormControl<string>('', {
    nonNullable: true,
    validators: [Validators.required, Validators.maxLength(250)],
  });

  protected readonly form = new FormGroup({
    comment: this.commentControl,
  });

  constructor(public loadingService: LoadingService) {
    super();
  }
  onInjectInputs({ clientId }: { clientId: number }): void {
    this.clientIdSubject$.next(clientId);
  }

  ChangeTab(value: number) {
    this.activeTab.set(value);
  }

  SendComment() {
    for (const i in this.form.controls) {
      this.form.get(i)?.updateValueAndValidity();
    }
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();

    const clientId = this.clientId();

    if (this.form.valid && clientId !== undefined) {
      this.infoService
        .saveComment(clientId, this.form.getRawValue().comment)
        .subscribe({
          next: () => {
            this.eventsService.success('Комментарий успешно сохранён!');
            this.close();
          },
          error: (e) => {
            this.eventsService.throwError('Что-то пошло не так :(');
            this.dismiss(e);
          },
        });
    }
  }

  getTimeZone(timezone: number) {
    const timezones = [
      {id: 2, name: 'Калининград (UTC+2)'},
      {id: 3, name: 'Москва, Санкт-Петербург (UTC+3)'},
      {id: 4, name: 'Самара, Волгоград (UTC+4)'},
      {id: 5, name: 'Екатеринбург, Челябинск (UTC+5)'},
      {id: 6, name: 'Омск (UTC+6)'},
      {id: 7, name: 'Новосибирск, Красноярск (UTC+7)'},
      {id: 8, name: 'Иркутск, Улан-Удэ (UTC+8)'},
      {id: 9, name: 'Чита, Якутск (UTC+9)'},
      {id: 10, name: 'Хабаровск, Владивосток (UTC+10)'}
    ]

    for(let i = 0; i<timezones.length; i++) {
      if(timezones[i]['id'] == timezone) {
        return timezones[i]['name']
      }
    }
    return  'GMT'+timezone;
  }
}
